<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
  page: {
    title: "Nouveau département",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader },
  data() {
    return {
      title: "Nouveau département",
      items: [
        {
          text: "Hiérarchie",
          href: "/"
        },
        {
          text: "Départements",
          href: "/"
        },
        {
          text: "Nouveau",
          active: true
        }
      ],
      deptForm: {
        deptName: "",
        deptAdr: "",
        deptAc: "",
        deptDivision: "",
      },

      divisions: [],
      
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false
    };
  },
  validations: {
    deptForm: {
      deptName: { required, maxLength: maxLength(64) },
      deptAdr: { required, maxLength: maxLength(64) },
      deptAc: { required, maxLength: maxLength(64) },
      deptDivision: { required },
    },
    
  },
  methods: {
    fetchDivisionsList(){
      this.$http
        .post("/hierarchy/divisions/list")
        .then((res) => (this.divisions = res.data.original.list))
        .catch(() => {})
        .finally(() => {});
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch()
      if(!this.$v.$invalid){
        var router = this.$router;
        this.$http.post('/hierarchy/departments/store', this.deptForm)
  
        .then((res) => {
            //Perform Success Action
            var status = res.data.original.status;
            switch(status){
              case 200: 
                this.$toast.success(res.data.original.msg);
                router.push({ name: 'hierarchy.departments.index' });              
              break;
  
              case 500: 
                this.$toast.warning(res.data.original.msg);
              break;
            }
        })
        .catch((error) => {
          this.$toast.success(error.message);
        }).finally(() => {
            //Perform action in always
        });
      }
      // if form is valid, submit to api
    },
  
  },

  mounted() {
    this.fetchDivisionsList();
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="deptName">Désignation</label>
                    <input
                      id="deptName"
                      v-model="deptForm.deptName"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value="Mark"
                      :class="{ 'is-invalid': submitted && $v.deptForm.deptName.$error }"
                    />
                    <div v-if="submitted && $v.deptForm.deptName.$error" class="invalid-feedback">
                      <span v-if="!$v.deptForm.deptName.required">Le nom est obligatoire.</span>
                      <span v-if="!$v.deptForm.deptName.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom02">Addresse</label>
                    <input
                      id="validationCustom02"
                      v-model="deptForm.deptAdr"
                      type="text"
                      class="form-control"
                      placeholder=""
                      value="Otto"
                      :class="{ 'is-invalid': submitted && $v.deptForm.deptAdr.$error }"
                    />
                    <div v-if="submitted && $v.deptForm.deptAdr.$error" class="invalid-feedback">
                      <span v-if="!$v.deptForm.deptAdr.required">L'addresse est obligatoire.</span>
                      <span v-if="!$v.deptForm.deptAdr.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom03">Division</label>
                    <select
                      id="projectname"
                      v-model="deptForm.deptDivision"
                      type="text"
                      class="form-control"
                      :class="{
                        'is-invalid':
                          submitted && $v.deptForm.deptDivision.$error,
                      }"
                      placeholder=""
                    >
                      <option value=""></option>
                      <option
                        v-for="division in divisions"
                        v-bind:value="division.id"
                        v-bind:key="division.id"
                      >
                        {{ division.name }}
                      </option>
                    </select>
                    <div v-if="submitted && $v.deptForm.deptDivision.$error" class="invalid-feedback">
                      <span v-if="!$v.deptForm.deptDivision.required">La division est obligatoire.</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="validationCustom03">Compte analytique</label>
                    <input
                      id="validationCustom03"
                      v-model="deptForm.deptAc"
                      type="text"
                      class="form-control"
                      placeholder=""
                      :class="{ 'is-invalid': submitted && $v.deptForm.deptAc.$error }"
                    />
                    <div v-if="submitted && $v.deptForm.deptAc.$error" class="invalid-feedback">
                      <span v-if="!$v.deptForm.deptAc.required">Le compte analytique est obligatoire.</span>
                      <span v-if="!$v.deptForm.deptAc.maxLength" >La taille du champs est limitée a 64 charactères.</span>
                    </div>
                  </div>
                </div>
              </div>
              <button class="btn btn-primary" type="submit">Enregistrer</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>